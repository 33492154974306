import React, { useState, useCallback, useEffect } from "react"

/* Import Global Component(s) */
import Header from "~components/header/header"
import Seo from "~components/seo/seo"

/* Import Scene Specific Components */
import Console from "~components/console/console"
// import Whitney from "~components/whitney/whitney"
// import TechnologyTree from "~sceneComponents/technologyTree/technologyTree"
import Loading from "~components/loading/loading"

/* Import Local Style(s) */
import * as styles from "./index.module.scss"

const ExampleCompositionPage = () => {
  return (
    <div className={`index debugging ${styles.index}`}>
      <Header />
      <Loading />
      <Console />
    </div>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default ExampleCompositionPage
